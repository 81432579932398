<template>
  <div class="page">
    <div class="standalone-content">
      <h1>About Tueday</h1>
      <router-link class="arrow" to="/">← <span>Back Home</span></router-link>
      <p>Here at Tueday we're trying to challenge the way that we takes notes everyday.</p>
      <p>
        We ourselves were sick of rushing to write down a note or task during a meeting on a scrap of paper or aimlessly
        trying to find an appropriate folder to start a new Google Doc that would ultimately end up getting disorganised
        and eventually lost anyway.
      </p>
      <p>
        After many years of trying different note taking practises, apps and techniques we decided to try solve our
        problem in a way that made sense to us and solved the problem we were having. This is why we came up with Tueday
        - Note taking at the speed of thought.
      </p>

      <h2>How it works</h2>
      <p>1. Just add notes as they happen into the Today section.</p>
      <p>2. Convert your notes into Tasks, Events or Ideas.</p>
      <p>3. Tasks that you create will appear in the 'Tasks' tab for easy reference later.</p>
      <p>4. Tag your notes so you can organise them find them more easily later.</p>
      <p>5. Search through your notes using our lightening fast search tools.</p>
      <p>6. Create an account to securely sync your data between all your devices. <em class="em">(Coming Soon)</em></p>
      <p>
        As days progress, your previous Notes will get pushed down the page and form a living 'Index' of whats been
        happening day-to-day. We've found this to be super valuable when it comes to reflecting on your day and
        understanding what you achieved as well as helping to plan out your next day.
      </p>

      <h2>Data &amp; Privacy</h2>
      <p>
        We take Data security and privacy very seriously. Thats why right now, we don't store any of your data on our
        servers. This means that every note you write down is currently stored on your own personal device.
      </p>
      <p>
        Whilst we love this approach, it does come with some downsides - namely the ability to backup your data and to
        support cross device access (i.e accessing your notes from your Mobile Phone).
      </p>
      <p>
        In the near future we will be introducing Accounts which will allow you to Opt-In to securely syncing up your
        data to our Servers. This will enable multi-device support and the ability to recover your notes if anything
        happens to your device.
      </p>
      <p>
        Read our full <router-link to="/about/privacy">Privacy Policy</router-link> and
        <router-link to="/about/terms">Terms</router-link> to learn more about our Data &amp; Privacy practises.
      </p>

      <h2>Contact us</h2>
      <p>
        If you have feedback about our Tueday, we'd love to hear from you. We are constantly striving to figure out how
        we can improve our features and functionality.
      </p>
      <p>Email as at: <a href="mailto:hi@tueday.com">hi@tueday.com</a></p>
      <hr />
      <router-link class="arrow" to="/">← <span>Back Home</span></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
};
</script>
